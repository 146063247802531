import { useState, useEffect } from "react";
import Slider from "react-slick";
export async function getPartnerPageData(): Promise<any> {
  const dummyData =
    process.env.NODE_ENV === "development"
      ? "https://cmsadmin.recsource.co.uk/api/partners?populate=deep"
      : "https://cmsadmin.recsource.co.uk/api/partners?populate=deep";
  return fetch(`${dummyData}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}
function extractImageUrls(data: any[]) {
  return data.flatMap(item =>
      item.attributes.image.data.map((image: { attributes: { url: any; }; }) => image.attributes.url)
  );
}
export const CarouselBox = () => {
  const [homePageData, setHomePageData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPartnerPageData();        
        setHomePageData(extractImageUrls(response.data));
      } catch (error) {
        console.error("Error fetching partner page data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: homePageData.length <= 5 ? 3 : 5,
    slidesToScroll: 1,
    swipe: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="footerCarousel" className={`bg-white rounded-2xl h-[100px] sm:h-[150px]`}>
      <Slider {...settings}>
        {homePageData.map((src: string, index: number) => (
          <div key={index} className="h-full" style={{ minWidth: 200 }}>
            <div className="flex justify-center items-center h-full">
              <img
                src={`https://cmsadmin.recsource.co.uk${src}`}
                alt={`Slide ${index + 1}`}
                className="h-full max-h-full w-auto object-contain"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
